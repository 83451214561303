@import './assets/styling/variables';
@import 'animate.css';
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;300;400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Outfit', sans-serif;
  color: $text-color;

  p {
    font-weight: 300;

  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 15px;
  }
}

.app {
  position: relative;
  min-height: 100vh;
}

.app-body {
  width: $app-width;
  margin-inline: auto;
}


// Responsive design code

// Phone & tablet general
@media (max-width: 767px) {
  .app-body {
    width: 95%;
  }
}

// Phone
@media (max-width: 480px) {

}

// Tablets
@media (min-width: 481px) and (max-width: 767px) {
  
}

// Small Computer
@media (min-width: 768px) and (max-width: 1224px){
  
}

// Big computer
@media (min-width: 1225px) {
  .app-body {
    width: 55%;
  }
}