@import '../../assets/styling/variables';

.navbar {
    width: 100%;
    height: 110px;
    margin-bottom: 30px;
}

.navbar-container {
    display: flex;
    height: 100%;
    width: $app-width;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid gray;
}

.navbar-logo {
    margin: 0 auto;
}

.logo {
    color: $primary-color;
    font-size: 34px;
}


// Delete ??
.navbar-links {
    display: flex;
}

.nav-link {
    padding-right: 20px;
    text-decoration: none;
}

.nav-link:last-of-type {
    padding-right: 0;
}


// Phone & tablet general
@media (max-width: 676px) {
    .navbar {
        height: 80px;
    }
}

// Phone
@media (max-width: 480px) {
    
}

// Tablets
@media (min-width: 481px) and (max-width: 767px) {
    
}

// Small Computer
@media (min-width: 1025px) and (max-width: 1280px){
    
}

// Big computer
@media (min-width: 1281px) {
    
}