@import '../../assets/styling/variables';

.card {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .card-title {
        color: $primary-color;
        text-align: center;
        min-height: 70px;
        font-weight: 400;
        margin-bottom: 10px;
        font-weight: 700;
    }

    .card-text {
        text-align: center;
        font-weight: 300;
    }
}

// Phone & tablet general
@media (max-width: 767px) {
    .card {
        .card-title {
            min-height: 0px;
        }
    }
}

// Phone
@media (max-width: 480px) {
    
}

  
// Tablets
@media (min-width: 481px) and (max-width: 767px) {
    
}
  
// Small Computer
@media (min-width: 1025px) and (max-width: 1280px){
    .card {
        .card-title {
            min-height: 0px;
        }
    }
}
  
// Big computer
@media (min-width: 1281px) {
    .card {
        .card-title {
            min-height: 0px;
        }
    }
}
