@import '../../assets/styling/variables';

.footer {
    position: relative;
    bottom: 0;
    width: 100%;
    background-color: black;
    padding-top: 20px;
    margin-top: 50px;

    .footer-container {
        width: $app-width;
        margin-inline: auto;

        h1 {
            color: white;
            font-size: 34px;
            text-align: center;
            margin-bottom: 20px;
        }
    
        .footer-links {
            display: flex;
            justify-content: space-between;
            height: 40px;
            margin-top: 5px;
    
            p {
                color: white;
                font-weight: 300;
                font-size: 14px;
            }
    
            a {
                cursor: pointer;
            }
    
            .footer-policies {
                display: flex;

                p {
                    cursor: pointer;
                }
    
                div {
                    margin-left: 15px;
                }
            }
        }
    }
}


// Responsive design code

// Phone & tablet general
@media (max-width: 767px) {

    .footer {
        .footer-container {
            width: 95%;

            .footer-links {
                height: 40px;
        
                p {
                    font-size: 12px;
                }
            }
        }
    }
}
  
// Phone
@media (max-width: 480px) {
    
}
  
// Tablets
@media (min-width: 481px) and (max-width: 767px) {
    
}
  
// Small Computer
@media (min-width: 1025px) and (max-width: 1280px){
    
}
  
// Big computer
@media (min-width: 1281px) {
    
}
