@import '../../assets//styling/variables';

.how-it-works {
    width: 100vw;
    margin-left: -10vw;
    background-color: $primary-color;
    height: 220px;
    padding-top: 20px;

    .how-it-works-container {
        width: 80%;
        margin: 0 auto;
        text-align: center;

        h2 {
            color: white;
            margin: 20px 0 0 0;
        }
    }
}

.step-by-step {
    background-color: white;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 70%;
    margin-inline: auto;
    position: relative;
    top: -80px;
    padding: 40px 20px;

    h2 {
        color: $primary-color;
        text-align: center;
        margin-bottom: 30px;
    }

    .step-count-container {
        display: flex;
        justify-content: center;
        height: 750px;
        margin-bottom: 30px;

        .step-count-sides {
            height: 100%;
            width: 40vw;
            display: grid;
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
            font-weight: 300;
        }

        .step-count-side-cell {
            display: flex;
            align-items: center;
        }

        .step-count-left {
            margin-right: 10px;
            text-align: center;
        }
    
        .step-count-middle {
            width: 10%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
    
            .circle {
                height: 50px;
                width: 50px;
                border-radius: 50%;
                background-color: $primary-color;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                margin: 10px;
            }
        
            .vertical-dash {
                width: 7px;
                height: 55px;
                background-color: $primary-color;
            }
        }
    
        .step-count-right {
            margin-left: 10px;
            text-align: center;
        }
    }

    h3 {
        text-align: center;
        margin-bottom: 30px;
    }
}


// Responsive design code

// Phone & tablet general
@media (max-width: 767px) {
    .how-it-works {
        margin-left: -2.5vw;
        height: 250px;

        .how-it-works-container {
            width: 90%;
        }
    }
    
    .step-by-step {
        width: 95%;
        padding: 40px 10px;
    
        h2 {
            color: $primary-color;
            text-align: center;
            margin-bottom: 30px;
        }
    
        .step-count-container {
            height: 1100px;
        
            .step-count-middle {
                .circle {
                    height: 45px;
                    width: 45px;
                    border-radius: 50%;
                    background-color: $primary-color;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    margin: 10px;
                }

                .vertical-dash {
                    height: 120px;
                }
            }
        }
    }
  }
  
// Phone
@media (max-width: 480px) {
    .how-it-works {
        height: 310px;
    }

    p {
        font-size: 18px;
    }
}
  
// Tablets
@media (min-width: 481px) and (max-width: 767px) {
    
}
  
// Small Computer
@media (min-width: 768px) and (max-width: 1224px){
    .how-it-works {
        height: 240px;

        .how-it-works-container {
            width: 90%;
        }
    }

    .step-by-step {
        width: 80%;
        padding: 40px 10px;
    
        h2 {
            color: $primary-color;
            text-align: center;
            margin-bottom: 30px;
        }
    
        .step-count-container {
            height: 900px;
        
            .step-count-middle {
                .vertical-dash {
                    height: 80px;
                }
            }
        }
    }
}

// Big computer
@media (min-width: 1225px) {
    .how-it-works {
        margin-left: -22.5vw;
    }
}