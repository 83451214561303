@import '../../assets/styling/variables';

.contact {
    width: 80%;
    margin: 0 auto;
}

.contact-btn {
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin: 0 auto;
    background-color: $primary-color;
    border: none;
    padding: 10px 10vw;
    border-radius: 10px;
    color: white;
    font-weight: 700;
    font-size: 23px;
}

.contact-btn:hover {
    opacity: 0.9;
    transform: scale(1.02);
}

.contact-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 0 40px 0;
    width: 50%;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
}

.contact-form-background {
    z-index: 2;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
}

.contact-form {
    width: 80%;
    margin: 0 auto;
}

.contact-form-cancel {
    display: flex;
    justify-content: right;
}

.form-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    label {
        margin-bottom: 5px;
    }

    input {
        padding: 5px;
    }

    textarea {
        height: 100px;
        padding: 5px;
    }
}

.contact-form-message {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 80%;
    margin: 0 auto;
    padding: 10px 0 10px 20px;
}

.success {
    border: 1px solid #33691e;
    background-color: #e6ffe3;
}

.error {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    border: 1px solid #b7171c;
    background-color: #ef9a9a;
}

.btn {
    background-color: $primary-color;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 2px;
    padding: 10px 0;
}

.btn:hover {
    opacity: 0.9;
}

.no-btn {
    border: none;
    background-color: transparent;
}


// Responsive design code

// Phone & tablet general
@media (max-width: 767px) {
    .contact-modal {
        width: 90%;
    }
}
  
// Phone
@media (max-width: 480px) {
    
}
  
// Tablets
@media (min-width: 481px) and (max-width: 767px) {
    
}
  
// Small Computer
@media (min-width: 1025px) and (max-width: 1280px){
    
}
  
// Big computer
@media (min-width: 1281px) {
    
}