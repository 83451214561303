@import '../../../assets//styling/variables';


span {
    color: $primary-color;
}


// Banner CSS
.banner-container {
    width: 100%;
    margin-bottom: 6vh;

    .banner-img {
        width: 90%;
        margin: 0 auto;
        margin-top: -80px;

        h1 {
            position: relative;
            top: 80px;
            right: 5vw;
            text-align: right;
            z-index: 1;
            font-size: 2vw;
        }

        img {
            max-width: 100%;
            height: auto;
            position: relative;
            top: 0;
        }
    }
}


// Mission Statement CSS
.mission-statement-container {
    width: 100%;
    margin-bottom: 10vh;

    p {
        text-align: center;
        margin-bottom: 20px;
    }

    .cards-container {
        width: 100%;

        .card-container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-column-gap: 10px;
        }
    }
}


// Markets CSS
.markets-container {
    h2 {
        text-align: center;
        margin-bottom: 20px;
    }

    .information-banner {
        width: 100vw;
        margin-left: -10vw;
        background-color: $primary-color;
        min-height: 150px;
        padding: 20px 0;

        .information-container {
            width: 90%;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 1fr 1fr 2fr 3fr;
            text-align: center;
            color: white;

            h3 {
                min-height: 45px;
                margin-bottom: 20px;
                color: white;
                font-size: 20px;
            }

            p {
                color: white;
                font-weight: 300;
            }
        }
    }
}


// Service offer CSS
.service-offer {
    // min-height: 100vh;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 10vh 0;

    .service-offer-container {
        margin: 0 auto;
        width: 100%;
    }

    h3 {
       text-align: center;
        margin-bottom: 30px; 
    }

    .service-img {
        margin: 0 auto;
        width: 80%;

        img {
            width: 100%;
            height: auto;
        }
    }

    .service-offer-list-container {
        margin: 0 auto;
        margin: 40px 0;
        width: 80%;

        h4 {
           margin-bottom: 10px;
           text-align: center;
        }

        ul {
            list-style-type: decimal;
            font-weight: 300;
            margin-left: 25px;
            text-align: center;
            list-style-position: inside;

            li {
                margin-bottom: 5px;
            }
        }
    }

    .service-offer-bespoke-container {
        margin: 0 auto;
        width: 80%;
        text-align: center;
    
        h4 {
            margin-bottom: 10px;
        }
    }
}


// About Us CSS
.about-us {
    width: 70%;
    margin: 0 auto;
    position: relative;
    top: -10px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .about-us-profile {
        width: 100%;
        text-align: center;
    }

    .about-us-pic {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin: 0 auto;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        p, h4 {
            color: white;
        }

        p {
            font-size: 14px;
        }
    }

    .pic1 {
        background-color: $primary-color;
    }

    .pic2 {
        background-color: $primary-color;
    }
}


// Responsive design code

// Phone & tablet general
@media (max-width: 767px) {

    // General
    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 22px;
    }

    // Banner
    .banner-container {
        .banner-img {
            width: 100%;
        
            h1 {
                font-size: 4vw;
            }
        } 
    }

    // Mission Statement
    .mission-statement-container {
        .cards-container {
            .card-container {
                display: grid;
                grid-template-rows: 1fr 1fr 1fr 1fr;
                grid-template-columns: 1fr;
                grid-row-gap: 10px;
            }
        }
    }

    // markets
    .markets-container {
        .information-banner {
            margin-left: -2.5vw;
            padding: 30px 10px;
            margin-bottom: 60px;
    
            .information-container {
                width: 90%;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 2fr 3fr;
                grid-row-gap: 30px;
    
                h3 {
                    min-height: 0px;
                    margin-bottom: 10px;
                }
            }
        }
    }

    // Service offer CSS
    .service-offer {
        display: flex;
        align-items: center;
        width: 100%;

        .service-offer-container {
            width: 100%;
            margin: 0 auto;
        }

        h2 {
        text-align: center;
            margin-bottom: 20px; 
        }

        .service-offer-list-container {
            width: 100%;
        }

        .service-offer-bespoke-container {
            width: 100%;
        }
    }

    // About Us CSS
    .about-us {
        width: 70%;
        height: 700px;
        top: -10px;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-row-gap: 30px;

        .about-us-profile {
            width: 100%;
            text-align: center;
        }

        .about-us-pic {
            width: 250px;
            height: 250px;
            border-radius: 50%;
            margin-inline: auto;
            margin-bottom: 20px;
        }
    }
}

// Phone
@media (max-width: 481px) {

    // markets
    .markets-container {
        .information-banner {    
            .information-container {
                width: 90%;
                margin: 0 auto;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 2fr;
                grid-row-gap: 30px;
    
                h3 {
                    min-height: 0px;
                    margin-bottom: 10px;
                }
    
                p {
                    color: white;
                    font-weight: 300;
                }
            }
        }
    }
}

// Tablets
@media (min-width: 481px) and (max-width: 767px) {
    
}

// Small Computer
@media (min-width: 768px) and (max-width: 1224px) {
    .banner-container {
        .banner-img {        
            h1 {
                font-size: 2.7vw;
            }
        } 
    }

    .service-offer {
        .service-offer-container {
            width: 100%;
            margin: 0 auto;
        }

        h2 {
            text-align: center;
            margin-bottom: 20px; 
        }

        .service-offer-list-container {
            width: 100%;
        }

        .service-offer-bespoke-container {
            width: 100%;
        }
    }
}

// Big computer
@media (min-width: 1225px) {
    .banner-container {
        margin-bottom: 10vh;
    
        .banner-img {
            width: 90%;
        }
    }

    .markets-container {
        .information-banner {
            margin-left: -22.5vw;

            .information-banner-wide-screen {
                margin: 0 auto;
                width: 75vw;
            }
        }
    }


    .service-offer {
        .service-offer-container {
            width: 100%;
            margin: 0 auto;
        }

        h2 {
            text-align: center;
            margin-bottom: 20px; 
        }

        .service-offer-list-container {
            width: 100%;
        }

        .service-offer-bespoke-container {
            width: 100%;
        }
    }
}